import styles from './Crew.module.scss';
import profileImage1 from '../../images/new/Ljubisa Bogdanovic.jpeg';
import profileImage2 from '../../images/new/Miroslav Mucaji.jpeg';
import profileImage3 from '../../images/new/Stefan Kankaras.jpeg';
import profileImage4 from '../../images/new/Natalija Bogdanovic.jpeg';
import profileImage5 from '../../images/new/Marko Zecevic.jpeg';
import profileImage6 from '../../images/new/Diego Aguirre.jpeg';
import profileImage7 from '../../images/new/Camila Munoz.jpeg';
import groupPhoto from '../../images/new/group-photo.jpeg';

const profiles = [
  {
    id: 1,
    name: 'Ljubisa Bogdanovic',
    image: profileImage1,
    role: 'Captain in Command',
    experience: '20 years of experience',
    bio: 'With a decade of experience commanding the Riana Yacht, Captain Ljubisa Bogdanovic brings exceptional leadership and maritime expertise to the crew. His extensive knowledge ensures the safety and smooth operation of every voyage.',
    linkedin:
      'https://www.linkedin.com/in/ljubi%C5%A1a-bogdanovi%C4%87-2299a317'
  },
  {
    id: 2,
    name: 'Miroslav Mucaji',
    image: profileImage2,
    role: 'Deckhand, Senior Bosun, and First Officer',
    experience: '15 years of experience',
    bio: "Miroslav Mucaji has been a vital part of the Riana Yacht team for a decade, excelling in multiple roles from deckhand to senior bosun and first officer. His dedication and skills are crucial to maintaining the yacht's operations.",
    linkedin: 'https://www.linkedin.com'
  },
  {
    id: 3,
    name: 'Stefan Kankaras',
    image: profileImage3,
    role: 'Engineer',
    experience: '5 years with Riana Yacht',
    bio: 'As the engineer of Riana Yacht, Stefan Kankaras is responsible for the technical integrity and maintenance of the vessel. His five years of experience on the yacht ensure all systems run smoothly and efficiently.',
    linkedin: 'https://www.linkedin.com'
  },
  {
    id: 4,
    name: 'Natalija Bogdanovic',
    image: profileImage4,
    role: 'Chief Stewardess',
    experience: '10 years of experience',
    bio: 'Natalija Bogdanovic has been enhancing the guest experience on Riana Yacht for five years. Her attention to detail and dedication to service make her an indispensable part of the crew.',
    linkedin: 'https://www.linkedin.com'
  },
  {
    id: 5,
    name: 'Marko Zecevic',
    image: profileImage5,
    role: 'Deckhand',
    experience: '3 years of experience',
    bio: "Marko Zecevic started as a seasonal deckhand and has now become a permanent member of the crew. His commitment and hard work contribute significantly to the yacht's daily operations.",
    linkedin: 'https://www.linkedin.com'
  },
  {
    id: 6,
    name: 'Diego Aguirre',
    image: profileImage6,
    role: 'Chef',
    experience: '8 years of experience',
    bio: 'Diego Aguirre brings two decades of culinary experience to his first season with Riana Yacht. His culinary creations are set to delight guests with exquisite flavours and presentations.',
    linkedin: 'https://www.linkedin.com/in/diego-aguirre-641186185/'
  },
  {
    id: 7,
    name: 'Camila Munoz',
    image: profileImage7,
    role: 'Second Stewardess and Sous Chef',
    experience: '4 years of experience',
    bio: 'With six years in the hospitality industry, Camila Munoz serves as both the second stewardess and Cordon Bleu trained Sous Chef. Her dual role enhances the guest experience with exceptional service and culinary support.',
    linkedin: 'https://www.linkedin.com/in/camila-mu%C3%B1oz-flores-2a23b5272'
  }
];

const Profiles = () => (
  <section className={styles.profilesSection}>
    <h1 className={styles.heading}>Meet The Crew</h1>
    <div className={styles.profilesContainer}>
      {profiles.map((profile) => (
        <div key={profile.id} className={styles.profileCard}>
          <img
            src={profile.image}
            className={styles.profileImage}
            alt="profileimage"
          />
          <div className={styles.profileInfo}>
            <a
              href={profile.linkedin}
              target="_blank"
              rel="noopener noreferrer"
              className={styles.profileName}
            >
              {profile.name}
            </a>
            <p className={styles.profileRole}>{profile.role}</p>
            <p className={styles.profileExperience}>{profile.experience}</p>
            <p className={styles.profileBio}>{profile.bio}</p>
          </div>
        </div>
      ))}
    </div>
    <div className={styles.groupPhotoContainer}>
      <img src={groupPhoto} className={styles.groupPhoto} alt="profileImage" />
    </div>
  </section>
);

export default Profiles;
